import { createContext } from 'react';
import { Chat } from '../types';
/**
 * Interface representing the context type for user login management.
 *
 * - setUser - A function to update the current user in the context,
 *             which take User object representing the logged-in user or null
 *             to indicate no user is logged in.
 */
export interface ChatContextType {
  chat: Chat | null;
  chats: Chat[] | null;
  setChat: (chat: Chat | null) => void;
  setChats: (chats: Chat[] | null) => void;
}

const ChatContext = createContext<ChatContextType | null>(null);

export default ChatContext;
