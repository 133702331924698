import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Layout from './layout';
import Login from './login';
import { FakeSOSocket, User } from '../types';
import LoginContext from '../contexts/LoginContext';
import UserContext from '../contexts/UserContext';
import QuestionPage from './main/questionPage';
import TagPage from './main/tagPage';
import NewQuestionPage from './main/newQuestion';
import NewAnswerPage from './main/newAnswer';
import AnswerPage from './main/answerPage';
import ChatPage from './main/chatPage';
import GitHubCallback from './login/github';
import Logout from './login/logout';
import ProfilePage from './main/profilePage';
import PageNotFound from './main/pageNotFound';
import GoogleCallback from './login/googleCallback';
import { getUser } from '../services/authService';
import GithubLocalRedirect from './login/githubLocalRedirect';

const ProtectedRoute = ({
  user,
  socket,
  children,
}: {
  user: User | null;
  socket: FakeSOSocket | null;
  children: JSX.Element;
}) => {
  if (!user || !socket) {
    return <Navigate to='/' />;
  }

  return <UserContext.Provider value={{ user, socket }}>{children}</UserContext.Provider>;
};

const FakeStackOverflow = ({ socket }: { socket: FakeSOSocket | null }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [cookies, , removeCookie] = useCookies(['refreshToken', 'accessToken']);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        if (!cookies.accessToken || !cookies.refreshToken) {
          removeCookie('accessToken');
          removeCookie('refreshToken');
          setUser(null);
          setLoaded(true);
          return;
        }

        // API call to check user authentication based on cookies
        const response = await getUser();

        const { data } = response.data;

        if (data.user && response.data.success) {
          setUser(data.user); // Set authenticated user
        }
      } catch (error) {
        setUser(null); // Ensure the user is null if authentication fails
      }

      setLoaded(true);
    };

    checkAuthentication();
  }, [cookies, removeCookie]);

  return (
    <LoginContext.Provider value={{ setUser }}>
      <Toaster position='bottom-left' />
      {loaded ? (
        <Routes>
          {/* Public Route */}
          <Route path='/' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/auth/gh/callback' element={<GitHubCallback />} />
          <Route path='/auth/gh/callback/local' element={<GithubLocalRedirect />} />
          <Route path='/auth/google/callback' element={<GoogleCallback />} />

          {/* Protected Routes */}
          <Route
            element={
              <ProtectedRoute user={user} socket={socket}>
                <Layout />
              </ProtectedRoute>
            }>
            <Route path='/home' element={<QuestionPage />} />
            <Route path='/tags' element={<TagPage />} />
            <Route path='/question/:qid' element={<AnswerPage />} />
            <Route path='/new/question' element={<NewQuestionPage />} />
            <Route path='/new/answer/:qid' element={<NewAnswerPage />} />
            <Route path='/chat' element={<ChatPage />} />
            <Route path='/profile' element={<ProfilePage />} />
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      ) : null}
    </LoginContext.Provider>
  );
};

export default FakeStackOverflow;
