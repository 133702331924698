import { useEffect, useRef, useState } from 'react';

interface SelectProps {
  items: SelectItem[];
  label?: string;
  className?: string;
}

interface SelectItem {
  name: string;
  value: string;
  selected?: boolean;
}

const Select = (props: SelectProps) => {
  const { items, label, className = '' } = props;

  const [selected, setSelected] = useState(items.find(i => i.selected) || items[0]);
  const [showDropdown, setShowDropdown] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
        setShowDropdown(false);
      }
    };

    // Attach the listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className={className}>
      {label ? (
        <label id='listbox-label' className='block text-sm font-medium text-gray-900'>
          Assigned to
        </label>
      ) : null}
      <div className='relative'>
        <button
          type='button'
          onClick={() => setShowDropdown(!showDropdown)}
          className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm/6'
          aria-haspopup='listbox'
          aria-expanded='true'
          aria-labelledby='listbox-label'>
          <span className='flex items-center'>
            <span className='block truncate'>{selected.name}</span>
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
            <svg
              className='size-5 text-gray-400'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
              data-slot='icon'>
              <path
                fillRule='evenodd'
                d='M10.53 3.47a.75.75 0 0 0-1.06 0L6.22 6.72a.75.75 0 0 0 1.06 1.06L10 5.06l2.72 2.72a.75.75 0 1 0 1.06-1.06l-3.25-3.25Zm-4.31 9.81 3.25 3.25a.75.75 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.06-1.06L10 14.94l-2.72-2.72a.75.75 0 0 0-1.06 1.06Z'
                clipRule='evenodd'
              />
            </svg>
          </span>
        </button>

        <ul
          className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'
          tabIndex={-1}
          hidden={!showDropdown}
          role='listbox'
          aria-labelledby='listbox-label'
          aria-activedescendant='listbox-option-3'>
          {items.map((item, index) => (
            <li
              className='relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-100'
              id='listbox-option-0'
              key={index}
              onClick={() => {
                setSelected(item);
                setShowDropdown(false);
              }}
              role='option'>
              <div className='flex items-center'>
                <span className='block truncate font-normal'>{item.name}</span>
              </div>
              {selected.value === item.value ? (
                <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600'>
                  <svg
                    className='size-5'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'
                    data-slot='icon'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </span>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Select;
