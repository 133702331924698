import { useEffect, useState } from 'react';
import { getAllUsers } from '../../../../../../services/userService';
import { updateChat } from '../../../../../../services/chatService';
import useChatContext from '../../../context/useChatContext';
import useUserContext from '../../../../../../hooks/useUserContext';
import { Chat, User } from '../../../../../../types';

const EditChatModal = ({ onClose }: { onClose: () => void }) => {
  const { chat, setChats, setChat, chats } = useChatContext();
  const { user } = useUserContext();
  const [chatName, setChatName] = useState(chat?.chatName || '');
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [newMembers, setNewMembers] = useState<{ username: string; id: string }[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUsersData = await getAllUsers();
        const updatedUsers = allUsers.filter(
          /* @ts-expect-error: member is not a string but fully populated user but expects it to be a string */
          u => !chat.members.some(member => member === u._id || member._id === u._id),
        );

        setAllUsers(allUsersData);
        setUsers(updatedUsers);
      } catch (err) {
        console.error('Error fetching users:');
      }
    };

    fetchUsers();
  }, [user._id, allUsers, chat?.members]);

  const handleSearch = (searchTextInput: string) => {
    setSearchText(searchTextInput);

    const updatedUsers = allUsers.filter(
      /* @ts-expect-error: member is not a string but fully populated user but expects it to be a string */
      u => !chat.members.some(member => member === u._id || member._id === u._id),
    );

    const filteredUsers = updatedUsers.filter(u =>
      u.username.toLowerCase().includes(searchTextInput.toLowerCase()),
    );
    setUsers(filteredUsers);
  };

  const addUser = (username: string, id: string) => {
    if (!newMembers.some(member => member.id === id)) {
      setNewMembers(prev => [...prev, { username, id }]);
    }
  };

  const leaveGroup = async () => {
    if (!chat || !user) return;

    const updatedMembers = chat.members.filter(memberId => memberId !== user._id);

    try {
      await updateChat(chat.chatId, { members: updatedMembers });

      const updatedChats = chats ? chats.filter(c => c.chatId !== chat.chatId) : [];
      setChats(updatedChats);
      setChat(null); // Clear current chat
      onClose(); // Close modal
    } catch (err) {
      console.error('Error leaving group:');
    }
  };

  const handleSaveChanges = async () => {
    if (!chat) return;

    const updatedChat: Partial<Chat> = {
      chatName: chatName || chat.chatName,
      members: [...chat.members, ...newMembers.map(member => member.id)],
    };

    try {
      const savedChat = await updateChat(chat.chatId, updatedChat);

      const updatedChats = chats
        ? chats.map(c => (c.chatId === savedChat.chatId ? savedChat : c))
        : [savedChat];

      setChats(updatedChats);

      setChat(savedChat); // Update current chat
      onClose();
    } catch (err) {
      console.error('Error saving chat changes:');
    }
  };

  const removeUser = (id: string) => {
    setNewMembers(prev => prev.filter(u => u.id !== id));
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
      <div className='bg-white rounded-lg p-6 w-96'>
        <h2 className='font-bold text-xl mb-4'>Edit Chat</h2>

        {/* Chat Name */}
        <input
          type='text'
          value={chatName}
          onChange={e => setChatName(e.target.value)}
          placeholder='Group Name'
          className='border rounded w-full px-2 py-1 mb-4'
        />

        {/* Members */}
        <div className='mb-4'>
          <h3 className='font-semibold mb-2'>Current Members:</h3>
          {chat?.members.map(member => (
            // @ts-expect-error:  member is not a string but fully populated user but expects it to be a string
            <p key={member._id} className='bg-gray-200 rounded px-4 py-1 mb-2'>
              {/* @ts-expect-error: member is not a string but fully populated user but expects it to be a string */}
              {allUsers.find(u => u._id === member._id || u._id === member)?.username ||
                user.username}
            </p>
          ))}
          {newMembers.map(u => (
            <span key={u.id} className='inline-block bg-gray-200 rounded-full px-4 py-1 mr-2 mb-2'>
              {u.username}
              <button onClick={() => removeUser(u.id)} className='ml-2 text-red-500'>
                x
              </button>
            </span>
          ))}
        </div>

        {/* Add Members */}
        <input
          type='text'
          value={searchText}
          onChange={e => handleSearch(e.target.value)}
          placeholder='Add Members...'
          onFocus={() => setIsInputFocused(true)} // Show options on focus
          onBlur={() => setTimeout(() => setIsInputFocused(false), 200)} // Hide options after blur with delay
          className='border rounded w-full px-2 py-1 mb-4'
        />
        {isInputFocused && users.length > 0 && (
          <div className='border rounded bg-gray-100 max-h-40 overflow-y-auto'>
            {users.map(u => (
              <div
                key={u._id}
                onClick={() => addUser(u.username, u._id)}
                className='cursor-pointer hover:bg-gray-200 p-2'>
                {u.username}
              </div>
            ))}
          </div>
        )}

        {/* Action Buttons */}
        <div className='flex justify-center'>
          <button
            onClick={leaveGroup}
            className='px-4 py-2 text-red-500 border rounded w-full hover:bg-red-100'>
            Leave Group
          </button>
        </div>

        <div className='flex justify-between mt-12'>
          <button onClick={onClose} className='px-4 py-2 border rounded bg-gray-200'>
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            className='px-4 py-2 border rounded bg-blue-500 text-white hover:bg-blue-600'>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditChatModal;
