import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useLoginContext from '../../hooks/useLoginContext';

const Logout = () => {
  const navigate = useNavigate();
  const { setUser } = useLoginContext();
  const [, , removeCookie] = useCookies(['refreshToken', 'accessToken']);

  useEffect(() => {
    const logout = async () => {
      try {
        setUser(null);
        removeCookie('refreshToken');
        removeCookie('accessToken');
        navigate('/');
      } catch (error) {
        throw new Error('Logout failed');
      }
    };
    logout();
  });

  return <p>Logging Out...</p>;
};

export default Logout;
