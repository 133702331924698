/**
 * Custom hook to check if the user is logged in.
 *
 * @returns boolean - Returns true if the user is logged in, otherwise false.
 */

import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

type UserLoggedInType = {
  authenticated: boolean;
};

const useLoggedIn = (): UserLoggedInType => {
  const context = useContext(UserContext);

  if (context === null) {
    return { authenticated: false };
  }

  return { authenticated: context.user !== null };
};

export default useLoggedIn;
