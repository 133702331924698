import apiClient from './api';

export const loginGoogleAPI = (googleToken: string) =>
  apiClient.post(`/auth/google`, { googleToken });
export const loginGitHubAPI = () => apiClient.post(`/auth/github`);

export const getUser = () =>
  apiClient.get('/user/me', {
    withCredentials: true, // Include cookies in the request
  });
