import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function GithubLocalRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Function to extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    // Redirect with the code to the local app
    if (code) {
      window.location.href = `/auth/gh/callback?code=${code}`;
    }
  }, [navigate, location]);

  return <div>Loading...</div>;
}

export default GithubLocalRedirect;
