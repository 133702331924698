import { useContext } from 'react';
import ChatContext, { ChatContextType } from '../../../../contexts/ChatContext';

const useChatContext = (): ChatContextType => {
  const context = useContext(ChatContext);

  if (context === null) {
    throw new Error(
      'ChatContext is null. Make sure you are using this hook within a ChatProvider.',
    );
  }

  return context;
};

export default useChatContext;
