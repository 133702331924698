import './index.css';
import { NavLink } from 'react-router-dom';

const NAV_LINKS = [
  {
    name: 'Questions',
    to: '/home',
    id: 'menu_questions',
  },
  {
    name: 'Tags',
    to: '/tags',
    id: 'menu_tag',
  },
  {
    name: 'Chat',
    to: '/chat',
    id: 'menu_chat',
  },
];

/**
 * The SideBarNav component has two menu items: "Questions" and "Tags".
 * It highlights the currently selected item based on the active page and
 * triggers corresponding functions when the menu items are clicked.
 */
const SideBarNav = () => (
  <div className='flex flex-col w-[15%] p-2 items-center border-r gap-2'>
    {NAV_LINKS.map(navLink => (
      <NavLink
        key={navLink.id}
        to={navLink.to}
        className={({ isActive }) =>
          `menu_button rounded ${isActive ? 'bg-slate-200 text-blue-500' : ''}`
        }>
        {navLink.name}
      </NavLink>
    ))}
  </div>
);

export default SideBarNav;
