import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ChatSelection from './components/ChatSelection';
import ChatProvider from './context/ChatProvider';
import ChatWindow from './components/ChatWindow';

const ChatPage = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Convert preselect to a boolean
  const preselect = queryParams.preselect === 'true';

  return (
    <ChatProvider>
      <div className='flex h-full'>
        {/* Chat Selection */}
        <div className='w-96 h-full border-r border-blue-500'>
          <ChatSelection
            preselect={preselect}
            preselectedUser={{
              name: queryParams.name as string,
              id: queryParams.id as string,
            }}
          />
        </div>

        {/* Chat Window */}
        <div className='flex-grow h-full'>
          <ChatWindow />
        </div>
      </div>
    </ChatProvider>
  );
};

export default ChatPage;
