import { useState, ReactNode, useEffect } from 'react';
import ChatContext from '../../../../contexts/ChatContext';
import { Chat } from '../../../../types';
import { getChatsByMember } from '../../../../services/chatService';
import useUserContext from '../../../../hooks/useUserContext';

const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [chat, setChat] = useState<Chat | null>(null);
  const [chats, setChats] = useState<Chat[] | null>([]);
  const { user, socket } = useUserContext();

  // Utility function to sort chats by lastUpdated
  const sortChatsByLastUpdated = (cs: Chat[]) =>
    cs.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());

  // Fetch chats for the logged-in user
  useEffect(() => {
    const fetchChats = async () => {
      try {
        if (user?._id) {
          const memberChats = await getChatsByMember(user._id); // Fetch chats by member ID
          const sortedChats = sortChatsByLastUpdated(memberChats); // Sort chats
          setChats(sortedChats);

          // Retrieve the saved current chat from localStorage
          const savedChat = localStorage.getItem(`currChat_${user._id}`);
          if (savedChat) {
            const parsedChat = JSON.parse(savedChat) as Chat;
            const validChat = sortedChats.find(c => c.chatId === parsedChat.chatId);
            if (validChat) {
              setChat(validChat); // Restore the chat if it exists in the fetched chats
            }
          }
        }
      } catch (err) {
        console.error('Error fetching chats');
      }
    };

    fetchChats();
  }, [user]);

  // Save the current chat to localStorage whenever it changes
  useEffect(() => {
    if (chat && user?._id) {
      localStorage.setItem(`currChat_${user._id}`, JSON.stringify(chat));
    }
  }, [chat, user]);

  useEffect(() => {
    socket.on('chatUpdate', (updatedChat: Chat) => {
      setChats(prevChats => {
        const existingChats = prevChats ?? [];
        const chatIndex = existingChats.findIndex(c => c.chatId === updatedChat.chatId);

        let newChatsList;
        if (chatIndex !== -1) {
          // Chat exists, replace it with the updated chat
          newChatsList = [...existingChats];
          newChatsList[chatIndex] = updatedChat;
        } else {
          // Chat doesn't exist, add it to the array
          newChatsList = [...existingChats, updatedChat];
        }

        // Sort the updated chats list by lastUpdated
        return sortChatsByLastUpdated(newChatsList);
      });
    });

    return () => {
      socket.off('chatUpdate');
    };
  }, [socket]);

  const value = {
    chat,
    chats,
    setChat,
    setChats,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatProvider;
