import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';
import Button from '../items/Button';
import triggerGoogleLogin from '../googlePopup/googlePopup';

const Login = () => {
  const { loginWithGitHub, loginWithGoogle } = useLogin();
  const navigate = useNavigate();
  const [cookies] = useCookies(['refreshToken', 'accessToken']);

  useEffect(() => {
    if (cookies.accessToken && cookies.refreshToken) {
      // Redirect to home page if user is already authenticated
      navigate('/home');
    }
  }, [cookies, navigate]);

  const startGoogleLogin = async () => {
    try {
      const tokenResponse = await triggerGoogleLogin();
      if (!tokenResponse) {
        return;
      }
      loginWithGoogle(tokenResponse);
    } catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown in the triggerGoogleLogin function
      } else {
        // Handle other types of errors
      }
    }
  };

  return (
    <div className='flex items-center justify-center h-screen text-sm'>
      <div className='flex flex-col p-10 border rounded w-fit h-fit bg-slate-100'>
        <div className='pb-8'>
          <h1 className='text-2xl font-semibold text-center text-black '>FakeStackOverflow</h1>
        </div>
        <div className='flex gap-2 mt-4'>
          <Button onClick={startGoogleLogin}>Continue with Google</Button>
          <Button onClick={loginWithGitHub} style='secondary'>
            Continue with GitHub
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
