import { useState } from 'react';
import useAnswerPage from '../../../hooks/useAnswerPage';
import { downvoteQuestion, upvoteQuestion } from '../../../services/questionService';
import Select from '../../items/Select/Select';
import Textarea from '../../items/Textarea';
import Button from '../../items/Button';
import addAnswer from '../../../services/answerService';
import { Answer } from '../../../types';
import useUserContext from '../../../hooks/useUserContext';
import AnswerHeader from './answerHeader';
import ResponseBlock from './responseBlock';

/**
 * AnswerPage component that displays the full content of a question along with its answers.
 * It also includes the functionality to vote, ask a new question, and post a new answer.
 */
const AnswerPage = () => {
  const { question } = useAnswerPage();
  const { user } = useUserContext();

  // Page state management
  const [answerInput, setAnswerInput] = useState('');

  if (!question) {
    return null;
  }

  /**
   * Function to handle upvoting or downvoting a question.
   *
   * @param type - The type of vote, either 'upvote' or 'downvote'.
   */
  const handleVote = async (type: string) => {
    try {
      if (question._id) {
        if (type === 'upvote') {
          await upvoteQuestion(question._id, question.askedBy.username);
        } else if (type === 'downvote') {
          await downvoteQuestion(question._id, question.askedBy.username);
        }
      }
    } catch (error) {
      // Handle error
    }
  };

  const postAnswer = () => {
    // TODO: Add validation
    const answer: Answer = {
      text: answerInput,
      ansBy: user,
      ansDateTime: new Date(),
      comments: [],
    };
    addAnswer(question._id!, answer);
  };

  return (
    <div className='w-full h-fit'>
      {/* Answer header component */}
      <AnswerHeader {...question} />
      <ResponseBlock question={question} handleVote={handleVote} />
      <div className='flex flex-col'>
        {question.answers.length > 0 ? (
          // Question has answers
          <div className='border-t-2 py-4 my-4'>
            <div className='flex flex-row justify-between items-center'>
              <div className='font-semibold text-lg'>
                {question.answers.length} {question.answers.length > 1 ? 'Answers' : 'Answer'}
              </div>
              <div className='flex flex-row items-center gap-3'>
                <div className='text-sm'>Sorted By:</div>
                {/* Dropdown */}
                <Select
                  className='w-[170px]'
                  items={[
                    { name: 'Most Votes', value: 'mostVotes' },
                    { name: 'Newest', value: 'newest' },
                    { name: 'Oldest', value: 'oldest' },
                  ]}
                />
              </div>
            </div>
            {question.answers.map((answer, idx) => (
              <div key={idx}>
                <ResponseBlock answer={answer} handleVote={handleVote} />
              </div>
            ))}
            <div className='border-t-2 py-4 my-4'>
              <div className='font-semibold text-lg'>Your Answer</div>
              <Textarea
                className='mt-5'
                value={answerInput}
                setValue={setAnswerInput}
                placeholder='Enter something insightful!'
              />
              <Button style='primary' className='my-2' onClick={postAnswer}>
                Post Your Answer
              </Button>
            </div>
          </div>
        ) : (
          // Question has no answers
          <div className='border-t-2 py-4 my-4'>
            <div className='font-semibold text-lg'>Your Answer</div>
            <Textarea
              className='mt-5'
              value={answerInput}
              setValue={setAnswerInput}
              placeholder='Enter something insightful!'
            />
            <Button style='primary' className='my-2' onClick={postAnswer}>
              Post Your Answer
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnswerPage;
