import TagView from './tag';
import useTagPage from '../../../hooks/useTagPage';
import Input from '../../items/Input';
import Button from '../../items/Button';

/**
 * Represents the TagPage component which displays a list of tags
 * and provides functionality to handle tag clicks and ask a new question.
 */
const TagPage = () => {
  const { tlist, clickTag } = useTagPage();

  return (
    <>
      <div className='flex flex-col gap-2'>
        <h4 className='text-2xl font-medium'>Tags</h4>
        <p className='w-full md:max-w-[60%]'>
          A tag is a keyword or label that categorizes your question with other, similar questions.
          Using the right tags makes it easier for others to find and answer your question.
        </p>
        <div className='mt-2 flex flex-row justify-between items-center'>
          <Input placeholder='Filter tag by name' />
          <div className='flex flex-row gap-1'>
            {/* TODO: make this a multi-select input & add filtering support */}
            <Button style='secondary-outline'>Popular</Button>
            <Button style='secondary-outline'>Name</Button>
            <Button style='secondary-outline'>New</Button>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-4'>
        {tlist.map((tag, idx) => (
          <TagView key={idx} t={tag} clickTag={clickTag} />
        ))}
      </div>
    </>
  );
};

export default TagPage;
