import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // For generating unique messageId
import useChatContext from '../../context/useChatContext';
import useUserContext from '../../../../../hooks/useUserContext';
import { ChatMessage } from '../../../../../types';
import { getMessagesForChat, createChatMessage } from '../../../../../services/chatMessageService';
import EditChatModal from './components/EditChatModal';

const ChatWindow = () => {
  const { chat, chats, setChats } = useChatContext();
  const { user, socket } = useUserContext(); // Get the current user's info
  const [chatMessageInput, setChatMessageInput] = useState('');
  const [chatMessages, setChatMessages] = useState<ChatMessage[] | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for modal visibility
  const messageListRef = useRef<HTMLDivElement>(null); // Ref for the message list container

  // Fetch messages for the selected chat
  useEffect(() => {
    if (!chat) return;

    const fetchMessages = async () => {
      try {
        const messages = await getMessagesForChat(chat.chatId); // Fetch messages for the chat
        setChatMessages(messages.length > 0 ? messages : []);
      } catch (err) {
        setChatMessages([]); // Set to an empty array on error
      }
    };

    fetchMessages();
  }, [chat]);

  // Scroll to the top of the message list whenever messages are updated
  useEffect(() => {
    if (messageListRef.current && chatMessages) {
      messageListRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [chatMessages]);

  useEffect(() => {
    // Listen for real-time message updates
    socket.on('chatMessageUpdate', (newMessage: ChatMessage) => {
      if (newMessage.senderId !== user._id) {
        if (newMessage.chatId === chat?.chatId) {
          setChatMessages(prev => (prev ? [...prev, newMessage] : [newMessage]));

          // Update lastUpdate for the corresponding chat and move it to the top
          if (chats) {
            const now = new Date(newMessage.timeSent);
            const updatedChats = chats.map(c =>
              c.chatId === newMessage.chatId ? { ...c, lastUpdate: now } : c,
            );

            // Sort chats by `lastUpdate` and set updated chats
            setChats(
              updatedChats.sort(
                (a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime(),
              ),
            );
          }
        }
      }
    });

    // Clean up socket listener
    return () => {
      socket.off('chatMessageUpdate');
    };
  }, [chat, chats, socket, setChats, user._id]);

  // Handle sending a message
  const sendMessage = async () => {
    if (chatMessageInput.trim() === '' || !chat || !user) return;
    const now = new Date();

    const newMessage: ChatMessage = {
      chatId: chat.chatId,
      messageId: uuidv4(), // Generate unique ID for the message
      message: chatMessageInput,
      sender: user.username, // Use username as sender
      senderId: user._id, // Use user ID as senderId
      timeSent: now,
    };

    try {
      await createChatMessage(newMessage); // Send the new message to the backend
      setChatMessages(prevMessages =>
        prevMessages ? [...prevMessages, newMessage] : [newMessage],
      ); // Update state

      if (chats) {
        const updatedChats = chats.map(c =>
          c.chatId === chat.chatId ? { ...c, lastUpdate: now } : c,
        );

        // Sort chats by `lastUpdate` and set updated chats
        setChats(
          updatedChats.sort(
            (a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime(),
          ),
        );
      }
      setChatMessageInput(''); // Clear input
    } catch (err) {
      console.error('Error sending message');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    chat && (
      <div className='h-full flex flex-col'>
        {/* Chat Header */}
        <div className='flex justify-between items-center border-b-2 border-gray-500 py-5 px-4'>
          <h1 className='font-bold text-lg mt-2'>{chat.chatName}</h1>
          <button onClick={() => setIsEditModalOpen(true)} className='text-blue-500 mt-2'>
            Edit
          </button>
        </div>

        {/* Message List (Scrollable) */}
        <div
          className='flex-grow overflow-y-auto px-4 py-2'
          ref={messageListRef} // Attach ref to the message list container
          style={{
            maxHeight: 'calc(100vh - 10rem)',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {chatMessages === null ? (
            <p>Loading messages...</p>
          ) : chatMessages.length === 0 ? (
            <p>No messages yet.</p>
          ) : (
            chatMessages
              .slice()
              .reverse()
              .map(msg => (
                <div
                  /* @ts-expect-error: msg.senderId populated as whole user */
                  className={`py-2 ${msg.senderId._id === user?._id || msg.senderId === user?._id ? 'ml-auto' : ''}`}
                  key={msg.messageId}>
                  <p className='font-bold'>{msg.sender}</p>
                  <p>{msg.message}</p>
                  <p className='text-gray-500 text-sm'>{new Date(msg.timeSent).toLocaleString()}</p>
                </div>
              ))
          )}
        </div>

        {/* Input Field (Fixed at Bottom) */}
        <div className='border-t-2 border-gray-500 py-2 px-4'>
          <div className='flex items-center'>
            <input
              type='text'
              value={chatMessageInput}
              onChange={e => setChatMessageInput(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder='Type a message...'
              className='flex-grow px-2 py-1 border rounded'
            />
            <button
              onClick={sendMessage}
              className='ml-2 px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'>
              Send
            </button>
          </div>
        </div>
        {/* Edit Chat Modal */}
        {isEditModalOpen && <EditChatModal onClose={() => setIsEditModalOpen(false)} />}
      </div>
    )
  );
};

export default ChatWindow;
