import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getTagsWithQuestionNumber } from '../services/tagService';
import { NotificationUpdatePayload, TagData } from '../types';
import useUserContext from './useUserContext';

/**
 * Custom hook for managing the tag page's state and navigation.
 *
 * @returns tlist - An array of tag data retrieved from the server
 * @returns clickTag - Function to navigate to the home page with the selected tag as a URL parameter.
 */
const useTagPage = () => {
  const { socket, user } = useUserContext();
  const navigate = useNavigate();
  const [tlist, setTlist] = useState<TagData[]>([]);

  /**
   * Function to navigate to the home page with the specified tag as a search parameter.
   *
   * @param tagName - The name of the tag to be added to the search parameters.
   */
  const clickTag = (tagName: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set('tag', tagName);

    navigate(`/home?${searchParams.toString()}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getTagsWithQuestionNumber();
        setTlist(res || []);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    /**
     * Function to handle notification updates.
     *
     * @param notification - The updated notification object.
     */
    const handleNotificationUpdate = (notification: NotificationUpdatePayload) => {
      if (notification.receiverId === user._id) {
        toast.success(
          `${notification.senderName} mentioned you in chatroom ${notification.chatName}! Head to the chat page to take a look.`,
          {
            duration: 5000,
          },
        );
      }
    };

    socket.on('notificationUpdate', handleNotificationUpdate);

    return () => {
      socket.off('notificationUpdate', handleNotificationUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return { tlist, clickTag };
};

export default useTagPage;
