import { handleHyperlink } from '../../../tool';
import { Answer, Question } from '../../../types';
import Author from './author';
import Tags from './tags';
import Vote from './vote';

const ResponseBlock = ({
  question,
  answer,
  handleVote,
}: {
  question?: Question;
  answer?: Answer;
  handleVote: (vote: 'upvote' | 'downvote') => void;
}) => (
  <div className='flex flex-row my-3'>
    {/* Vote comp */}
    {question ? <Vote question={question} handleVote={handleVote} /> : null}
    {/* Text area */}
    <div className='pl-3 pt-1 w-full'>
      <div
        className='text-sm'
        dangerouslySetInnerHTML={{
          __html: handleHyperlink(question?.text || answer?.text || ''),
        }}></div>
      {question ? <Tags tags={question.tags} /> : null}
      {/* Author Info */}
      <Author question={question} answer={answer} />
    </div>
  </div>
);

export default ResponseBlock;
