import { TagData } from '../../../../types';
import useTagSelected from '../../../../hooks/useTagSelected';

/**
 * Props for the Tag component.
 *
 * t - The tag object.
 * clickTag - Function to handle the tag click event.
 */
interface TagProps {
  t: TagData;
  clickTag: (tagName: string) => void;
}

/**
 * Tag component that displays information about a specific tag.
 * The component displays the tag's name, description, and the number of associated questions.
 * It also triggers a click event to handle tag selection.
 *
 * @param t - The tag object .
 * @param clickTag - Function to handle tag clicks.
 */
const TagView = ({ t, clickTag }: TagProps) => {
  const { tag } = useTagSelected(t);

  return (
    <div
      className='w-full border p-4 rounded-md text-sm flex flex-col gap-2 cursor-pointer hover:bg-slate-50'
      onClick={() => {
        clickTag(t.name);
      }}>
      <a className='bg-gray-100 rounded px-2 py-1 text-sm text-gray-900 font-medium w-fit'>
        {tag.name}
      </a>
      <div className='tagDescription'>{tag.description}</div>
      <div className='text-slate-500'>{t.qcnt} questions</div>
    </div>
  );
};

export default TagView;
