import { useNavigate } from 'react-router-dom';
import useLoginContext from './useLoginContext';
import { loginGoogleAPI } from '../services/authService';

/**
 * Custom hook to handle login input and submission.
 *
 * @returns username - The current value of the username input.
 * @returns handleInputChange - Function to handle changes in the input field.
 * @returns handleSubmit - Function to handle login submission
 */
const useLogin = () => {
  const { setUser } = useLoginContext();
  const navigate = useNavigate();

  const loginWithGoogle = async (tokenResponse: string) => {
    try {
      const response = await loginGoogleAPI(tokenResponse);
      if (response.status !== 200) {
        throw new Error('Error while logging in with Google');
      }
      const { data } = response.data;

      setUser(data.user);

      navigate('/home', { replace: true });
    } catch (err) {
      throw new Error('Google login failed');
    }
  };

  const loginWithGitHub = async () => {
    try {
      const clientID = process.env.REACT_APP_GH_CLIENT_ID;
      const redirectURI = process.env.REACT_APP_GH_REDIRECT_URI;
      window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}`;
    } catch (err) {
      throw new Error('GitHub login failed');
    }
  };
  return { loginWithGitHub, loginWithGoogle };
};

export default useLogin;
