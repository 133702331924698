import { useNavigate } from 'react-router-dom';
import useHeader from '../../hooks/useHeader';
import useUserContext from '../../hooks/useUserContext';
import Input from '../items/Input';
import Dropdown from '../items/Dropdown/Dropdown';
import Button from '../items/Button';
import useLoggedIn from '../../hooks/useLoggedIn';

/**
 * Header component that renders the main title and a search bar.
 * The search bar allows the user to input a query and navigate to the search results page
 * when they press Enter.
 */
const Header = () => {
  const { val, handleInputChange, handleKeyDown } = useHeader();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { authenticated } = useLoggedIn();

  return (
    <div className='w-full h-[80px] gap-4 flex justify-between items-center bg-slate-100 px-10'>
      <a
        className='text-3xl text-nowrap font-semibold select-none cursor-pointer'
        href='/home'
        onClick={e => {
          e.preventDefault();
          navigate('/home');
        }}>
        Fake Stack Overflow
      </a>
      <div className='w-[90%] max-w-[500px]'>
        <Input
          id='searchBar'
          placeholder='Search ...'
          type='text'
          value={val}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className='flex items-center gap-2 w-fit'>
        {authenticated ? (
          <Dropdown
            items={[
              { name: 'Profile', href: '/profile' },
              { name: 'Sign Out', href: '/logout', style: 'bold' },
            ]}>
            <div
              className={`w-[30px] h-[30px] bg-slate-500 rounded-full overflow-hidden border-slate-300 border flex-shrink-0`}>
              <img className={'w-full h-full'} src={user.profileImage} alt='profile_image' />
            </div>
            {user.name}
          </Dropdown>
        ) : (
          <Button href='/login' style='primary'>
            Sign In
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
