import { useNavigate } from 'react-router-dom';
import { getMetaData } from '../../../tool';
import { Question } from '../../../types';
import AskQuestionButton from '../askQuestionButton';

const AnswerHeader = (question: Question) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-row justify-between border-b py-5'>
      <div className='flex flex-col'>
        <div className='text-xl font-semibold'>{question.title}</div>
        <div className='flex flex-row gap-3 text-sm items-center'>
          <div>
            <span className='text-slate-500'>Asked</span>{' '}
            {getMetaData(new Date(question.askDateTime))}{' '}
          </div>
          <div>
            <span className='text-slate-500'>Viewed</span> {question.views.length} times{' '}
          </div>
          <div
            className='flex flex-row gap-2 items-center cursor-pointer'
            onClick={() => {
              navigate(`/profile/${question.askedBy.username}`);
            }}>
            <div
              className={`w-[23px] h-[23px] bg-slate-500 rounded-md overflow-hidden border-slate-300 border flex-shrink-0`}>
              <img
                className={'w-full h-full'}
                src={question.askedBy.profileImage}
                alt='profile_image'
              />
            </div>
            <div className='font-medium text-blue-500'>{question.askedBy.name}</div>
          </div>
        </div>
      </div>
      <div>
        <AskQuestionButton />
      </div>
    </div>
  );
};

export default AnswerHeader;
