import React, { useState } from 'react';
import toast from 'react-hot-toast';
import useUserContext from '../../../hooks/useUserContext';
import Button from '../../items/Button';
import Input from '../../items/Input';
import updateUser from '../../../services/userService';
import useLoginContext from '../../../hooks/useLoginContext';
import Textarea from '../../items/Textarea';

const ProfilePage = () => {
  const { user } = useUserContext();
  const { setUser } = useLoginContext();

  // state handlers
  const [name, setName] = useState(user.name);
  const [workingFrom, setWorkingFrom] = useState(user.workingFrom);
  const [username, setUsername] = useState(user.username);
  const [profileImage, setProfileImage] = useState(user.profileImage);
  const [bio, setBio] = useState(user.bio);
  const [loading, setLoading] = useState(false);

  const triggerSave = async () => {
    if (
      name === user.name &&
      username === user.username &&
      bio === user.bio &&
      workingFrom === user.workingFrom
    ) {
      toast.error('No changes made');
      return;
    }

    setLoading(true);

    // API call to update user profile
    const response = await updateUser({ name, username, bio, workingFrom });
    if (response.data.success) {
      toast.success('Profile updated successfully');
      const { data } = response.data;
      setUser(data.updatedUser);
      setLoading(false);
    } else {
      toast.error(response.data.message || 'Error updating profile');
      setLoading(false);
    }
  };

  return (
    <div className='p-10'>
      <h4 className='font-semibold text-lg'>Profile Page</h4>
      <div className='flex flex-col max-w-[500px] gap-4'>
        <div
          className='w-full h-fit flex items-center gap-4 cursor-pointer'
          onClick={() => {
            // trigger file input click
            const fileInput = document.getElementById('profileImageFile');
            fileInput?.click();
          }}>
          <div className='my-4 w-[130px] h-[130px] bg-slate-500 rounded-full overflow-hidden relative border-slate-300 border'>
            <img className='w-full h-full' src={profileImage} alt='profile_image' />
          </div>
          <Button style='secondary' className='rounded-md !py-2'>
            Edit Photo
          </Button>
          <input
            id={'profileImageFile'}
            type='file'
            accept='image/*'
            hidden
            onChange={() => {
              const fileInput = document.getElementById('profileImageFile') as HTMLInputElement;
              if (fileInput.files && fileInput.files[0]) {
                const file = fileInput.files[0];
                const reader = new FileReader();
                reader.onload = () => {
                  setProfileImage(reader.result as string);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
        </div>
        <Input
          label='Name'
          placeholder={'Your name'}
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <Input
          label='Username'
          placeholder='Your username'
          value={username}
          onChange={e => {
            setUsername(e.target.value);
          }}
        />
        <Input label='Email' placeholder='Your email' disabled value={user.email} />
        <Textarea
          value={bio}
          onChange={e => {
            setBio(e.target.value);
          }}
          resize='vertical'
          label='User Bio'
          placeholder='Something insightful'
        />
        <Input
          label='Location'
          placeholder='Working from home'
          value={workingFrom}
          onChange={e => {
            setWorkingFrom(e.target.value);
          }}
        />
        <Button onClick={triggerSave} loading={loading}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProfilePage;
