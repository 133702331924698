import api from './config';
import { Chat } from '../types';

const CHAT_API_URL = `${process.env.REACT_APP_SERVER_URL}/chat`;

/**
 * Fetches all chats.
 *
 * @returns A promise resolving to an array of chats.
 * @throws Error if the request fails or the response status is not 200.
 */
export const getAllChats = async (): Promise<Chat[]> => {
  const res = await api.get(`${CHAT_API_URL}/all`);
  if (res.status !== 200) {
    throw new Error('Error fetching chats');
  }
  return res.data;
};

/**
 * Fetches chats for a specific member by their ID.
 *
 * @param memberId - The ID of the member.
 * @returns A promise resolving to an array of chats.
 * @throws Error if the request fails or the response status is not 200.
 */
export const getChatsByMember = async (memberId: string): Promise<Chat[]> => {
  const res = await api.get(`${CHAT_API_URL}/member/${memberId}`);
  if (res.status !== 200) {
    throw new Error('Error fetching chats for the member');
  }
  return res.data;
};

/**
 * Creates a new chat.
 *
 * @param chatData - The data for the new chat.
 * @returns A promise resolving to the created chat.
 * @throws Error if the request fails or the response status is not 201.
 */
export const createChat = async (chatData: Chat): Promise<Chat> => {
  const res = await api.post(`${CHAT_API_URL}/create`, chatData);
  if (res.status !== 201) {
    throw new Error('Error creating chat');
  }
  return res.data;
};

/**
 * Deletes a chat by its ID.
 *
 * @param chatId - The ID of the chat to delete.
 * @returns A promise resolving to a success message.
 * @throws Error if the request fails or the response status is not 200.
 */
export const deleteChat = async (chatId: string): Promise<void> => {
  const res = await api.delete(`${CHAT_API_URL}/${chatId}`);
  if (res.status !== 200) {
    throw new Error('Error deleting chat');
  }
};

/**
 * Updates the members of an existing chat.
 *
 * @param chatId - The ID of the chat to update.
 * @param members - The new members to add to the chat.
 * @returns A promise resolving to the updated chat.
 * @throws Error if the request fails or the response status is not 200.
 */
export const updateChatMembers = async (chatId: string, members: string[]): Promise<Chat> => {
  const res = await api.put(`${CHAT_API_URL}/${chatId}/members`, { members });
  if (res.status !== 200) {
    throw new Error('Error updating chat members');
  }
  return res.data;
};

/**
 * Updates the details of an existing chat, such as name or members.
 *
 * @param chatId - The ID of the chat to update.
 * @param updatedData - The partial data to update (e.g., chatName, members).
 * @returns A promise resolving to the updated chat.
 * @throws Error if the request fails or the response status is not 200.
 */
export const updateChat = async (
  chatId: string,
  updatedData: Partial<Chat>, // Accept partial data for updates
): Promise<Chat> => {
  const res = await api.put(`${CHAT_API_URL}/${chatId}`, updatedData); // Remove `/name`
  if (res.status !== 200) {
    throw new Error('Error updating chat');
  }
  return res.data;
};
