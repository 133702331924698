import { useNavigate } from 'react-router-dom';
import { getMetaData } from '../../../../tool';
import { Question } from '../../../../types';

/**
 * Interface representing the props for the Question component.
 *
 * q - The question object containing details about the question.
 */
interface QuestionProps {
  q: Question;
}

/**
 * Question component renders the details of a question including its title, tags, author, answers, and views.
 * Clicking on the component triggers the handleAnswer function,
 * and clicking on a tag triggers the clickTag function.
 *
 * @param q - The question object containing question details.
 */
const QuestionView = ({ q }: QuestionProps) => {
  const navigate = useNavigate();

  /**
   * Function to navigate to the home page with the specified tag as a search parameter.
   *
   * @param tagName - The name of the tag to be added to the search parameters.
   */
  const clickTag = (tagName: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set('tag', tagName);

    navigate(`/home?${searchParams.toString()}`);
  };

  /**
   * Function to navigate to the specified question page based on the question ID.
   *
   * @param questionID - The ID of the question to navigate to.
   */
  const handleAnswer = (questionID: string) => {
    navigate(`/question/${questionID}`);
  };

  return (
    <div
      className='flex p-5 gap-8 hover:bg-gray-50 cursor-pointer h-fit w-full relative border-b'
      onClick={() => {
        if (q._id) {
          handleAnswer(q._id);
        }
      }}>
      <div className='flex-col text-nowrap justify-center items-center text-sm h-full text-slate-800 font-medium'>
        <div>{q.upVotes.length || 0} votes</div>
        <div>{q.answers.length || 0} answers</div>
        <div className='text-orange-500'>{q.views.length} views</div>
      </div>
      <div className='w-full flex flex-col gap-1 justify-center h-fit'>
        <div className='text-lg text-blue-500 font-medium'>{q.title}</div>
        <div className=' text-slate-600 text-sm line-clamp-2 text-ellipsis overflow-hidden'>
          {q.text}
        </div>
        <div className='flex flex-row gap-2'>
          {q.tags.map((tag, idx) => (
            <button
              key={idx}
              className='bg-gray-100 rounded px-2 py-1 text-sm text-gray-900 font-medium'
              onClick={e => {
                e.stopPropagation();
                clickTag(tag.name);
              }}>
              {tag.name}
            </button>
          ))}
        </div>
      </div>
      <div className='w-fit flex-row flex gap-2 flex-nowrap text-nowrap absolute right-5 bottom-5 text-sm items-center'>
        <div className='flex flex-row gap-2 items-center cursor-pointer'>
          <div
            className={`w-[23px] h-[23px] bg-slate-500 rounded-md overflow-hidden border-slate-300 border flex-shrink-0`}>
            <img className={'w-full h-full'} src={q.askedBy.profileImage} alt='profile_image' />
          </div>
          <div className='font-medium text-blue-500'>{q.askedBy.name}</div>
        </div>
        <div className='text-slate-600'>asked {getMetaData(new Date(q.askDateTime))}</div>
      </div>
    </div>
  );
};

export default QuestionView;
