import React, { useState } from 'react';
import { OrderType, orderTypeDisplayName } from '../../../../types';
import AskQuestionButton from '../../askQuestionButton';
import Button from '../../../items/Button';

/**
 * Interface representing the props for the QuestionHeader component.
 *
 * titleText - The title text displayed at the top of the header.
 * qcnt - The number of questions to be displayed in the header.
 * setQuestionOrder - A function that sets the order of questions based on the selected message.
 */
interface QuestionHeaderProps {
  titleText: string;
  qcnt: number;
  setQuestionOrder: (order: OrderType) => void;
}

/**
 * QuestionHeader component displays the header section for a list of questions.
 * It includes the title, a button to ask a new question, the number of the quesions,
 * and buttons to set the order of questions.
 *
 * @param titleText - The title text to display in the header.
 * @param qcnt - The number of questions displayed in the header.
 * @param setQuestionOrder - Function to set the order of questions based on input message.
 */
const QuestionHeader = ({ titleText, qcnt, setQuestionOrder }: QuestionHeaderProps) => {
  const [selectedOrder, setSelectedOrder] = useState<OrderType>('newest');
  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='font-bold text-xl'>{titleText}</div>
        <AskQuestionButton />
      </div>
      <div className='flex items-center justify-between my-2'>
        <div id='question_count'>{qcnt} Questions</div>
        <div className='flex gap-1'>
          {Object.keys(orderTypeDisplayName).map((order, idx) => (
            <Button
              className='rounded-none !py-1 !px-2.5 !font-medium'
              key={idx}
              style={selectedOrder === order ? 'secondary-outline' : 'none'}
              onClick={() => {
                setSelectedOrder(order as OrderType);
                setQuestionOrder(order as OrderType);
              }}>
              {orderTypeDisplayName[order as OrderType]}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionHeader;
