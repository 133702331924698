import { useNavigate } from 'react-router-dom';
import { getFullDate } from '../../../tool';
import { Answer, Question } from '../../../types';
import useUserContext from '../../../hooks/useUserContext';

const Author = ({ question, answer }: { question?: Question; answer?: Answer }) => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const handleAuthorClick = () => {
    const author = question?.askedBy || answer?.ansBy;

    if (author) {
      if (author._id !== user._id) {
        navigate(
          `/chat?preselect=true&name=${encodeURIComponent(author.username)}&id=${author._id}`,
        );
      }
    }
  };

  return (
    <div className='flex flex-row justify-end text-sm' onClick={handleAuthorClick}>
      <div className='bg-slate-100 p-2 flex flex-col gap-1 rounded-sm shadow-sm min-w-[200px]'>
        <div className='text-gray-500'>
          {getFullDate(new Date(question?.askDateTime || answer?.ansDateTime || ''))}
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className='overflow-hidden border shadow-sm'>
            <img
              className='w-10 h-10'
              src={question?.askedBy.profileImage || answer?.ansBy.profileImage}
              alt='profile_image'
            />
          </div>
          <div className='flex flex-col'>
            <div className='font-medium text-blue-500'>
              {question?.askedBy.name || answer?.ansBy.name}
            </div>
            <div className='font-medium'>
              {question?.askedBy.workingFrom || answer?.ansBy.workingFrom}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Author;
