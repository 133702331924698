import { Tag } from '../../../types';

const Tags = ({ tags }: { tags: Tag[] }) => (
  <div className='flex flex-row gap-2 my-5'>
    {tags.map((tag, idx) => (
      <button
        key={idx}
        className='bg-gray-100 rounded px-2 py-1 text-sm text-gray-900 font-medium'
        onClick={e => {
          // clickTag(tag.name);
        }}>
        {tag.name}
      </button>
    ))}
  </div>
);

export default Tags;
