import { Question } from '../../../types';

const Vote = ({
  question,
  handleVote,
}: {
  question: Question;
  handleVote: (vote: 'upvote' | 'downvote') => void;
}) => (
  <div className='w-[50px] flex flex-col gap-1.5 text-center items-center'>
    <div
      onClick={() => handleVote('upvote')}
      className='w-[40px] h-[40px] border-2 border-slate-200 rounded-full cursor-pointer'
    />
    <div>{question.upVotes.length - question.downVotes.length}</div>
    <div
      onClick={() => handleVote('downvote')}
      className='w-[40px] h-[40px] border-2 border-slate-200 rounded-full cursor-pointer'
    />
  </div>
);

export default Vote;
