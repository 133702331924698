import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  withCredentials: true, // Include cookies in requests
  validateStatus: () => true,
});

// Function to refresh the access token
export const refreshAccessToken = async () => {
  const response = await apiClient.post(`/auth/refresh`);
  return response.data.accessToken; // Return the new access token
};

// Attach interceptor to handle token refreshing
apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Check if the error is due to an expired access token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      await refreshAccessToken();
      return apiClient(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default apiClient;
