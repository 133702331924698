import { User } from '../types';
import apiClient from './api';
import api from './config';

const updateUser = (updates: Partial<User>) => apiClient.put(`/user/me`, { ...updates });

const USER_API_URL = `${process.env.REACT_APP_SERVER_URL}/user`;

/**
 * Adds a new answer to a specific question.
 *
 * @param qid - The ID of the question to which the answer is being added.
 * @param ans - The answer object containing the answer details.
 * @throws Error Throws an error if the request fails or the response status is not 200.
 */
export const getAllUsers = async (): Promise<User[]> => {
  const res = await api.get(`${USER_API_URL}/all`);
  if (res.status !== 200) {
    throw new Error('Error while getting User');
  }

  return res.data.data.users;
};

export default updateUser;
