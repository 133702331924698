import { useEffect, useState } from 'react';
import useChatContext from '../../context/useChatContext';
import { Chat } from '../../../../../types';
import CreateChatModal from './components/CreateChatModal';

const ChatSelection = ({
  preselect,
  preselectedUser,
}: {
  preselect?: boolean;
  preselectedUser?: { name: string; id: string };
}) => {
  const { setChat, chat, chats } = useChatContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<{ username: string; id: string }[]>([]);

  useEffect(() => {
    if (preselect && preselectedUser) {
      setSelectedUsers([{ username: preselectedUser.name, id: preselectedUser.id }]);
      setIsModalOpen(true);
    }
  }, [preselect, preselectedUser]);

  const selectChat = (selectedChat: Chat) => {
    setChat(selectedChat);
  };

  const formatChatDate = (date: string | Date): string => {
    const chatDate = new Date(date); // Ensure the input is a Date object
    const today = new Date();

    // Check if the date is today
    const isToday =
      chatDate.getDate() === today.getDate() &&
      chatDate.getMonth() === today.getMonth() &&
      chatDate.getFullYear() === today.getFullYear();

    return isToday
      ? chatDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) // Format as time
      : chatDate.toDateString(); // Format as full date
  };

  return (
    <div className=' w-96 h-full border-r-2 border-gray-500 py-4'>
      <div className='flex justify-between border-b-2 border-gray-500 py-4 px-2'>
        <h1 className='font-bold text-lg'>Chat</h1>
        <button onClick={() => setIsModalOpen(true)} className='text-blue-500 underline'>
          Create
        </button>
      </div>
      {/* Container for the chats list, with overflow scrolling */}
      <div className='overflow-y-auto h-5/6 px-2 '>
        {chats && chats.length > 0 ? (
          chats.map(c => (
            <div
              className={`flex justify-between py-2 px-2 hover:bg-gray-100 ${
                chat && chat.chatId === c.chatId ? 'bg-gray-200' : ''
              }`}
              onClick={() => selectChat(c)}
              key={c.chatId}>
              <h1>{c.chatName}</h1>
              <h1>{formatChatDate(c.lastUpdate)}</h1> {/* Call the helper function */}
            </div>
          ))
        ) : (
          <h1>No Chats</h1>
        )}
      </div>

      {/* Render modal conditionally */}
      {isModalOpen && (
        <CreateChatModal onClose={() => setIsModalOpen(false)} preselectedUsers={selectedUsers} />
      )}
    </div>
  );
};

export default ChatSelection;
