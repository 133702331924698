const triggerGoogleLogin = async (): Promise<string> => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Replace with your client ID
  const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI; // Replace with your redirect URI
  const scope = 'profile email';
  const responseType = 'token';

  const googleOAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${encodeURIComponent(
    scope,
  )}`;

  const width = 500;
  const height = 600;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  return new Promise((resolve, reject) => {
    const popup = window.open(
      googleOAuthUrl,
      'googleLogin',
      `width=${width},height=${height},top=${top},left=${left}`,
    );

    if (!popup) {
      reject(new Error('Failed to open popup window.'));
      return;
    }

    const interval = setInterval(() => {
      try {
        if (popup.closed) {
          clearInterval(interval);
          reject(new Error('Popup closed by user.'));
        }

        if (popup.location && popup.location.href) {
          const popupUrl = new URL(popup.location.href);

          if (popupUrl.origin === window.location.origin) {
            const params = new URLSearchParams(popupUrl.hash.substring(1));
            const accessToken = params.get('access_token');

            if (accessToken) {
              clearInterval(interval);
              popup.close();
              resolve(accessToken);
            }
          }
        }
      } catch (error) {
        // Ignore cross-origin errors until the popup reaches the redirect URI
      }
    }, 500);
  });
};

export default triggerGoogleLogin;
