import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library
import { getAllUsers } from '../../../../../../services/userService';
import { createChat } from '../../../../../../services/chatService';
import useChatContext from '../../../context/useChatContext';
import { Chat, User } from '../../../../../../types';
import useUserContext from '../../../../../../hooks/useUserContext';

const CreateChatModal = ({
  onClose,
  preselectedUsers = [],
}: {
  onClose: () => void;
  preselectedUsers?: { username: string; id: string }[];
}) => {
  const { setChat, chats, setChats } = useChatContext();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]); // All users fetched from API
  const [selectedUsers, setSelectedUsers] = useState<{ username: string; id: string }[]>([]); // Track selected users with both username and ID
  const [searchText, setSearchText] = useState('');
  const [chatName, setChatName] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { user } = useUserContext();

  useEffect(() => {
    if (preselectedUsers.length > 0) {
      setSelectedUsers(preselectedUsers);
    }
  }, [preselectedUsers]);

  useEffect(() => {
    const getAllUsersCall = async () => {
      try {
        const allUsersData = await getAllUsers();
        const filteredUsers = allUsersData.filter(u => u._id !== user._id);

        setAllUsers(filteredUsers);
        setUsers(filteredUsers);
      } catch (err) {
        console.error('Error fetching users:');
      }
    };
    getAllUsersCall();
  }, [user._id]);

  const handleSearch = (searchTextInput: string) => {
    setSearchText(searchTextInput);

    // Filter users based on the search text
    const filteredUsers = allUsers.filter(u =>
      u.username.toLowerCase().includes(searchTextInput.toLowerCase()),
    );
    setUsers(filteredUsers);
  };

  const addUser = (username: string, id: string) => {
    if (!selectedUsers.some(u => u.id === id)) {
      setSelectedUsers(prev => [...prev, { username, id }]);
    }
  };

  const removeUser = (id: string) => {
    setSelectedUsers(prev => prev.filter(u => u.id !== id));
  };

  const handleCreateChat = async () => {
    if (!chatName) {
      return;
    }

    try {
      const chatMembers = selectedUsers.map(u => u.id); // Use IDs for creating the chat
      if (!chatMembers.includes(user._id)) {
        chatMembers.push(user._id);
      }

      const newChat = await createChat({
        chatName: chatName || selectedUsers.map(u => u.username).join(', '), // Use usernames for the chat name
        members: chatMembers,
        lastUpdate: new Date(),
        chatId: uuidv4(),
      });

      const newChats: Chat[] = [newChat, ...(chats || [])];
      setChats(newChats);
      setChat(newChat);
      onClose();
    } catch (err) {
      console.error('Failed to create chat:');
    }
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
      <div className='bg-white rounded-lg p-6 w-96'>
        <h2 className='font-bold text-xl mb-4'>Create a New Chat</h2>
        <input
          type='text'
          placeholder='Search users'
          value={searchText}
          onChange={e => handleSearch(e.target.value)}
          onFocus={() => setIsInputFocused(true)} // Show options on focus
          onBlur={() => setTimeout(() => setIsInputFocused(false), 200)} // Hide options after blur with delay
          className='border rounded w-full px-2 py-1 mb-4'
        />
        {isInputFocused && users.length > 0 && (
          <div className='mb-4 border rounded bg-gray-100 max-h-40 overflow-y-auto'>
            {users.map(u => (
              <div
                key={u._id}
                onClick={() => addUser(u.username, u._id)}
                className='cursor-pointer hover:bg-gray-200 p-2'>
                {u.username}
              </div>
            ))}
          </div>
        )}
        <div className='mb-4'>
          <h3 className='font-semibold mb-2'>Selected Members:</h3>
          {selectedUsers.map(u => (
            <span key={u.id} className='inline-block bg-gray-200 rounded-full px-4 py-1 mr-2 mb-2'>
              {u.username}
              <button onClick={() => removeUser(u.id)} className='ml-2 text-red-500'>
                x
              </button>
            </span>
          ))}
        </div>
        <input
          type='text'
          placeholder='Chat Name'
          value={chatName}
          onChange={e => setChatName(e.target.value)}
          className='border rounded w-full px-2 py-1 mb-4'
        />
        <div className='flex justify-end'>
          <button onClick={onClose} className='mr-2 px-4 py-2 border rounded bg-gray-200'>
            Cancel
          </button>
          <button
            onClick={handleCreateChat}
            className='px-4 py-2 border rounded bg-blue-500 text-white'>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateChatModal;
