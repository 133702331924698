import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoginContext from '../../hooks/useLoginContext';
import apiClient from '../../services/api';

const GitHubCallback = () => {
  const { setUser } = useLoginContext();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Send code to backend
      apiClient.post('/auth/github', { code }).then(response => {
        if (response.data.success) {
          const { data } = response.data;
          setUser(data.user);
          navigate('/home');
        } else {
          navigate('/');
        }
      });
    }
  }, [navigate, setUser]);

  return (
    <div className='flex items-center justify-center h-full text-lg font-semibold'>
      Processing GitHub login...
    </div>
  );
};

export default GitHubCallback;
