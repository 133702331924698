import api from './config';
import { ChatMessage } from '../types';

const CHAT_MESSAGE_API_URL = `${process.env.REACT_APP_SERVER_URL}/chatMessage`;

/**
 * Creates a new chat message.
 *
 * @param messageData - The data for the new chat message.
 * @returns A promise resolving to the created chat message.
 * @throws Error if the request fails or the response status is not 201.
 */
export const createChatMessage = async (messageData: ChatMessage): Promise<ChatMessage> => {
  const res = await api.post(`${CHAT_MESSAGE_API_URL}/create`, messageData);
  if (res.status !== 201) {
    throw new Error('Error creating chat message');
  }
  return res.data;
};

/**
 * Deletes a chat message by its ID.
 *
 * @param messageId - The ID of the chat message to delete.
 * @returns A promise resolving to a success flag.
 * @throws Error if the request fails or the response status is not 200.
 */
export const deleteChatMessage = async (messageId: string): Promise<void> => {
  const res = await api.delete(`${CHAT_MESSAGE_API_URL}/${messageId}`);
  if (res.status !== 200) {
    throw new Error('Error deleting chat message');
  }
};

/**
 * Updates an existing chat message by its ID.
 *
 * @param messageId - The ID of the chat message to update.
 * @param updatedMessage - The updated message data.
 * @returns A promise resolving to the updated chat message.
 * @throws Error if the request fails or the response status is not 200.
 */
export const editChatMessage = async (
  messageId: string,
  updatedMessage: Partial<ChatMessage>,
): Promise<ChatMessage> => {
  const res = await api.put(`${CHAT_MESSAGE_API_URL}/${messageId}`, updatedMessage);
  if (res.status !== 200) {
    throw new Error('Error updating chat message');
  }
  return res.data;
};

/**
 * Fetches all messages for a specific chat by chatId.
 *
 * @param chatId - The ID of the chat.
 * @returns A promise resolving to an array of chat messages.
 * @throws Error if the request fails or the response status is not 200.
 */
export const getMessagesForChat = async (chatId: string): Promise<ChatMessage[]> => {
  const res = await api.get(`${CHAT_MESSAGE_API_URL}/${chatId}`);
  if (res.status !== 200) {
    throw new Error('Error fetching messages for the chat');
  }
  return res.data;
};
